import MainWrapper from "../../Layouts/MainWrapper";

import {ReactComponent as NotFound} from "../../Assests/Images/notFound.svg";

import "./Styles.scss";

const ErrorNotFound = () => {
    return (
        <MainWrapper isHomepage={false} customTitle="Page not found">
            <div className="error-component">
                <NotFound className = "error-image"/>
                <h1>404</h1>
                <h2>Page Not Found</h2>
            </div>
        </MainWrapper>
    )
}

export default ErrorNotFound;