import { ContentTypes } from "./constants";

export default class ContentTypeBuilder {
	static GetContetnType(type: ContentTypes) {
		switch (type) {
			case ContentTypes.JSON:
				return "application/json";
			case ContentTypes.URL_ENCODED:
				return "application/x-www-form-urlencoded";
			case ContentTypes.MULTIPART:
				return "multipart/form-data";
			default:
				return "application/json";
		}
	}
}
