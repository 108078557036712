import {useTranslation} from "react-i18next";

import Authentication from "./Authentication";
import { useNavigate } from "react-router";

import "react-phone-number-input/style.css";

// @ts-ignore
import backgroundVideo from "../../../Assests/Videos/-7c4d-4e97-b351-f5bd3e107a6c.mp4";

import {useCountDownTimer} from "../../../Hooks/useCountDown";

import "./styles.scss";
import { ROUTE_CONSTANTS } from "../../../Routes/RouteConstants";

const Banner = () => {
    const {t} = useTranslation();
	const navigate = useNavigate();

    const timer = useCountDownTimer();
    return (
		<section className="u-align-center u-clearfix u-image u-shading u-section-1" id="carousel_5ad0"  data-image-width="1782" data-image-height="1080">
		<div className="u-clearfix u-sheet u-valign-middle-xs u-sheet-1">
		  <h1 className="u-custom-font u-font-montserrat u-text u-text-body-alt-color u-title u-text-1">Transform your finances with AI driven accounting and automated invoicing.</h1>
		  <a href="javascript:void(0);" onClick={() => navigate(ROUTE_CONSTANTS.BLOG)} className="u-active-white u-border-2 u-border-white u-btn u-button-style u-hover-white u-none u-text-active-black u-text-body-alt-color u-text-hover-black u-btn-1">Go to blog</a>
		</div>
	  </section>
    );
};

export default Banner;
