import MainWrapper from "../../Layouts/MainWrapper";
import Banner from "../Home/Banner/Banner";

import "./styles.scss";
import {SHOW_POTENTIAL_PROFIT, TOAST_BOTTOM_CENTER, TOAST_DURATION} from "../../Constants/Values";
import FeaturesSection from "../../Components/Sections/FeaturesSection/FeaturesSection";
import TechnologyOfTheFutureSection from "../../Components/Sections/TechnologyOfTheFutureSection/TechnologyOfTheFutureSection";
import IconItemsSection from "../../Components/Sections/IconItemsSection/IconItemsSection";
import NewsletterSection from "../../Components/Sections/NewsletterSection/NewsletterSection";
import AIDigitalPlatformSection from "../../Components/Sections/AIDigitalPlatformSection/AIDigitalPlatformSection";
import EssentialSection from "../../Components/Sections/EssentialSection/EssentialSection";
import TechOfFutureSection from "../../Components/Sections/TechOfFutureSection/TechOfFutureSection";
import React, { useState, FormEvent } from 'react';
import AuthService from "../../Services/Auth/AuthService";
import { useNavigate } from "react-router-dom";
import Toast from '../../Utils/ToastHandler';

const Login = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const navigate = useNavigate();

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		 AuthService.login({grant_type:"", username: username, password: password}).then((response) =>{
			console.log(response);
				navigate("/blog");

		}).catch((e)=>{
			Toast.error(
				e.response.data.error_description.replace("invalid_username_or_password", "Invalid username or password"),
				TOAST_DURATION,
				TOAST_BOTTOM_CENTER
			  );
		}
		);
		
	  };

    return (
        <MainWrapper>
<section className="u-align-center u-clearfix u-image u-shading u-section-1" id="carousel_5ad0"  data-image-width="1782" data-image-height="1080">
		<div className="u-clearfix u-sheet u-valign-middle-xs u-sheet-1">
		  <h1 className="u-custom-font u-font-montserrat u-text u-text-body-alt-color u-title u-text-1">Login</h1>
		  <div className="container1">
			<form onSubmit={handleSubmit}>
				<div className="inputGroup">
				<label htmlFor="username">Username</label>
				<input
					type="text"
					id="username"
					value={username}
					onChange={(e) => setUsername(e.target.value)}
					required
				/>
				</div>
				<div className="inputGroup">
				<label htmlFor="password">Password</label>
				<input
					type="password"
					id="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					required
				/>
				</div>
				<input type="submit" className="u-active-white u-border-2 u-border-white u-btn u-button-style u-hover-white u-none u-text-active-black u-text-body-alt-color u-text-hover-black u-btn-1" value={"Login"} />
			</form>
			</div>
		</div>
	  </section>
		
        </MainWrapper>
    )
}

export default Login;