import {Navigate, Route, Routes} from "react-router-dom";
import PrivateRoutes from "./PrivateRoute";
import PublicRoutes from "./PublicRoutes";
import PageNotFound from "../Components/Error";

import {ROUTE_CONSTANTS} from "./RouteConstants";

import TokenHandler from "../Utils/TokenHandler";
import Home from "../Pages/Home/Home";
import CookieConsent from "../Pages/CookieConsent/CookieConsent";
import Login from "../Pages/Login/Login";
import Onboarding from "../Pages/Onboarding/Onboarding";
import ErrorNotFound from "../Pages/Error/ErrorNotFound";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import Blog from "../Pages/Blog/Blog";
import BlogItem from "../Pages/Blog/BlogItem";
import Register from "../Pages/Register/Regsiter";

type RequireAuthProps = {
    children: any;
    redirectTo: string;
};
const Router = () => {
    const IsLoggedIn = ({children, redirectTo}: RequireAuthProps) => {
        const hasToken = TokenHandler?.getToken();
        const externalReference = TokenHandler?.getDecodedToken()?.UserExternalReference;
        return hasToken && !externalReference ? children : <Navigate to={redirectTo} replace={true}/>;
    };

    return (
        <Routes>
            {/* <Route
                path={ROUTE_CONSTANTS.ONBOARDING}
                element={
                    <IsLoggedIn redirectTo={ROUTE_CONSTANTS.HOME}>
                        <Onboarding/>
                    </IsLoggedIn>
                }
            /> */}

            <Route path={ROUTE_CONSTANTS.HOME} element={<Home/>}/>
                <Route path={ROUTE_CONSTANTS.COOKIE} element={<CookieConsent/>}/>
				<Route path={ROUTE_CONSTANTS.BLOG} element={<Blog/>}/>
				<Route path={"/blogs"} element={<Blog/>}/>
				<Route path={"/blogitem/*"} element={<BlogItem/>}/>

				<Route path={ROUTE_CONSTANTS.LOGIN} element={<Login/>}/>
				<Route path={ROUTE_CONSTANTS.REGISTER} element={<Register/>}/>

                <Route path={ROUTE_CONSTANTS.PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
                <Route
                    path="*"
                    element={<ErrorNotFound />}
                />

            {/* <Route element={<PrivateRoutes/>}>
                <Route path={ROUTE_CONSTANTS.DASHBOARD} element={<Dashboard/>}/>
                <Route path={ROUTE_CONSTANTS.SETTINGS} element={<Settings/>}/>
                <Route path={ROUTE_CONSTANTS.TOP_UP} element={<TopUp/>}/>
                <Route path={ROUTE_CONSTANTS.TRANSACTION} element={<Dashboard/>}/>
                <Route path={ROUTE_CONSTANTS.PROFILE} element={<Profile/>}/>
                <Route path={"/"} element={<Dashboard/>}/>
                <Route
                    path="*"
                    element={<PageNotFound errorCode="404" loggedIn={true}/>}
                />
            </Route> */}


        </Routes>
    );
};

export default Router;
