import { useEffect, useState } from "react";

export default function useMatchMedia(query: string) {
	const [matches, setMatches] = useState(window.matchMedia(query).matches);

	useEffect(() => {
		const media = window.matchMedia(query);

		if (media.matches !== matches) setMatches(media.matches);

		const listener = () => setMatches(media.matches);

		if (media.addEventListener) {
			media.addEventListener("change", listener);
		} else media.addListener(listener);

		return () =>
			media.removeEventListener
				? media.removeEventListener("change", listener)
				: media.removeListener(listener);
	}, [matches, query]);

	return { match: matches };
}
