import {LoginInterface, PhoneAuthInterface, UserToken} from "../../Models/Auth";
import Token from "../../Utils/TokenHandler";
import AuthBaseService from "../AuthBaseService";
import FactoryService from "../FactoryService";

const factoryService = new FactoryService();

class AuthService {
    private GET_TOKEN_ENDPOINT: string = "connect/token";
    private PHONE_AUTHORIZATION: string = "Auth/device/authorization";
    private PHONE_REGISTRATION: string = "Auth/device/registration";

    async refreshToken(): Promise<UserToken> {
        try {
            const response = await (
                await factoryService.create(AuthBaseService)
            ).postAsJson(
                this.GET_TOKEN_ENDPOINT,
                {
                    grant_type: "refresh_token",
                    refresh_token: Token.getRefreshToken(),
                },
                false,
            );

            Token.setToken(response.access_token);
            Token.setRefreshToken(response.refresh_token);

            return response;
        } catch (error) {
            throw error;
        }
    }

    async login(payload: LoginInterface) {
       	payload.grant_type="password";

		const response = await (
			await factoryService.create(AuthBaseService)
		).postAsJson(
			this.GET_TOKEN_ENDPOINT,
			payload,
			false,
		);

		Token.setToken(response.access_token);
		Token.setRefreshToken(response.refresh_token);
		return response;       
    }

    async registerPhoneNumber(phone: string) {
        const payload = {
            "phoneNumber": phone
        }

        return await (
            await factoryService.create(AuthBaseService)
        ).postAsJson(this.PHONE_REGISTRATION, payload, false);
    }

    async verifyOTP(otp: string) {

        try {
            const response = await (
                await factoryService.create(AuthBaseService)
            ).postAsJson(
                this.GET_TOKEN_ENDPOINT,
                {
                    device_code: otp,
                    grant_type: 'urn:ietf:params:oauth:grant-type:device_code',
                },
                false,
            );

            Token.setToken(response.access_token);
            Token.setRefreshToken(response.refresh_token);
            return response;
        } catch (error) {
            throw error;
        }
    }

}

export default new AuthService();
