import MainWrapper from "../../Layouts/MainWrapper";
import "./BlogItem.scss";
import BlogHeader from "./BlogComponents/BlogHeader/BlogHeader";
import ArticlesService from "../../Services/ArticlesService";
import React, { useState, useEffect } from 'react';
import striptags from 'striptags';
import { useLocation } from "react-router-dom";

const BlogItem = () => {
	const [article, setArticle] = useState<any>({ translations: [{ title:""}]});
		const location = useLocation();
		const path = location.pathname;
		const lastParam = path.split('/').pop()??"";
	useEffect(() => {

  
		// Fetch data from service
		const fetchData = async () => {
			
		  try {
			const response = await ArticlesService.getArticle(lastParam);

			setArticle(response);
		  } catch (error) {
			console.error('Error fetching data:', error);
		  }
		};
	
		fetchData();
	  }, []); // Empty dependency array means this effect runs once after the initial render

	  
    return (
		<MainWrapper>

        <BlogHeader/>

		<div className="site-content">
			<div className="content-area-item">
				
				<main className="blog-item-site-main" id="main">
					         
       
					<article id="post-300" className="post-300 post type-post status-publish format-standard hentry category-football-accumulators category-football-corners category-football-goals">
						<div className="inside-article">
							
							
							<h1 className="entry-title">
								<a href="#" rel="bookmark">{article?.translations[0]?.title}</a>
							</h1>            
							
							<div className="entry-summary">
								<p>  
									<div dangerouslySetInnerHTML={{ __html: article?.translations[0]?.content }}/>
									
								</p>
							</div>
						</div>
					</article>

					
		    	</main>
			</div>
			
		</div>
		</MainWrapper>
    );
}

export default BlogItem;