export const ROUTE_CONSTANTS = {
  HOME: "/",
  REGISTER: "/register",
  DASHBOARD: "/dashboard",
  COOKIE: "/cookie",
  TRANSACTION: "/transactions",
  SETTINGS: "/settings",
  ONBOARDING: "/onboarding",
  TELEGRAM: "/telegram",
  ERROR_PAGE: "/not-found",
  PRIVACY_POLICY: "/privacy-policy",
  BLOG: "/blog",
  BLOGITEM: "/blogitem",
  LOGIN: "/login",
  CUSTOM_HEADER: "/custom-header",
  PROFILE: "/profile",
};
