interface StorageInterface {
  get: (key: string) => string | null;
  saveToLocalStorage: (key: string, value: string) => void;
  delete: (key: string) => void;
  clear: () => void;
}

class StorageHandler implements StorageInterface {
  saveToLocalStorage(key: string, data: any) {
    localStorage.removeItem(key);
    localStorage.setItem(key, data);
  }
  get(key: string) {
    return localStorage.getItem(key);
  }
  delete(key: string) {
    return localStorage.removeItem(key);
  }
  clear() {
    localStorage.clear();
  }
}

export default new StorageHandler();
