import MainWrapper from "../../Layouts/MainWrapper";
import Banner from "./Banner/Banner";



import "./styles.scss";
import {SHOW_POTENTIAL_PROFIT} from "../../Constants/Values";
import FeaturesSection from "../../Components/Sections/FeaturesSection/FeaturesSection";
import TechnologyOfTheFutureSection from "../../Components/Sections/TechnologyOfTheFutureSection/TechnologyOfTheFutureSection";
import IconItemsSection from "../../Components/Sections/IconItemsSection/IconItemsSection";
import NewsletterSection from "../../Components/Sections/NewsletterSection/NewsletterSection";
import AIDigitalPlatformSection from "../../Components/Sections/AIDigitalPlatformSection/AIDigitalPlatformSection";
import EssentialSection from "../../Components/Sections/EssentialSection/EssentialSection";
import TechOfFutureSection from "../../Components/Sections/TechOfFutureSection/TechOfFutureSection";

const Home = () => {
    return (
        <MainWrapper>

            <Banner/>
{/* 
			<IconItemsSection/>
            <TechnologyOfTheFutureSection/>
			<FeaturesSection/>
			<AIDigitalPlatformSection/>
			<EssentialSection/>
			<TechOfFutureSection/>
			<NewsletterSection/> */}
        </MainWrapper>
    )
}

export default Home;