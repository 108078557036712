import MainWrapper from "../../Layouts/MainWrapper";
import "./Blog.scss";
import BlogHeader from "./BlogComponents/BlogHeader/BlogHeader";
import ArticlesService from "../../Services/ArticlesService";
import React, { useState, useEffect } from 'react';
import striptags from 'striptags';
import PageNotFound from "../../Components/Error";

const Blog = () => {
	const [dataList, setDataList] = useState<any[]>([]);
	const [articlesCount, setArticlesCount] = useState<number>(1);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(5);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const params = new URLSearchParams(window.location.search);
				const page = params.get('page') ?? "1";
				const pageNumber = parseInt(page) ?? 1;
	
				const response = await ArticlesService.getArticles(null, pageNumber, pageSize);
				const articlesCount = response.slug_count[0].count.slug;
				const totalPages = Math.ceil(articlesCount / pageSize);
				
				setPageNumber(pageNumber);
				setArticlesCount(articlesCount);
				setTotalPages(totalPages);
				setDataList(response.articles);
		  	} catch (error) {
				console.error('Error fetching data:', error);
			}
		};


		fetchData();
	  }, []); // Empty dependency array means this effect runs once after the initial render

	  
    return (
		<MainWrapper>

        <BlogHeader/>

		<div className="site-content">
			<div className="content-area">
				<main className="site-main" id="main">
					<div className="breadcrumbs">
						<a href="/">Home</a>
					</div>

					{dataList.map((item, index) => (
          
       
					<article id="post-300" className="post-300 post type-post status-publish format-standard hentry category-football-accumulators category-football-corners category-football-goals">
						<div className="inside-article">
							<div className="blog-image">
								<img src={"https://directus.invopayment.com/assets/"+item.image.id} alt="Blog Image" />
							</div>
							<div className="blog-date-author">
								<span>{item.published_date}</span>
								<span className="blog-separator">-</span>
								<span>{item.author}</span>
							</div>
							<h2 className="entry-title">
								<a href="#" rel="bookmark">{item.translations[0].title}</a>
							</h2>            
							
							<div className="entry-summary">
								<p>  {striptags(item.translations[0].content).replace("&nbsp;","").substring(0, 400)}… 
									<div className="read-more-button">
										<a title={item.translations[0].title} className="read-more" href={"/blogitem/"+item.slug} aria-label={item.translations[0].title}>
											Read more
										</a>
									</div>
								</p>
							</div>
						</div>
						<div className="blog-space">&nbsp;</div>
					</article>

					))}

					<nav id="nav-below" className="paging-navigation" aria-label="Archive Page">
						<span className="screen-reader-text">Post navigation</span>
						
						{(articlesCount > pageSize) && (
								<div className="nav-links">
									{(pageNumber > 1) && (
									<a className="next page-numbers" href={ "/blog?page=" + (pageNumber-1)} >
										<span className="gp-icon icon-arrow-right">
												{"< Prev" } 
										</span>
									</a>
									)}

									<span aria-current="page" className="page-numbers">
										<span className="gp-icon icon-arrow-right">
												{ pageNumber + " of " + totalPages } 
										</span>
									</span>

									{(pageNumber < totalPages) && (
									<a className="next page-numbers" href={ "/blog?page=" + (pageNumber+1)}>
										<span className="gp-icon icon-arrow-right">
											Next {">"}
										</span>
									</a>
									)}
								</div>	
							)
						}
					</nav>
		    	</main>
			</div>
			<div className="side-bar">
				
			</div>
		</div>
		</MainWrapper>
    );
}

export default Blog;