const {
    REACT_APP_ENV,
    REACT_APP_VERSION
} = (window as any).__env__;

type Environment = {
    REACT_APP_IDENTITYSERVER_API_BASE_URL: string;
    CLIENT_SECRET: string;
    REACT_APP_MAIN_BASE_URL: string;
    REACT_APP_PROFILE_BASE_URL: string;
    REACT_APP_ACCOUNTING_BASE_URL: string;
    REACT_APP_GRAPHQL_BASE_URL: string;
    REACT_APP_ASSETS_BASE_URL: string;
    REACT_APP_VERSION: string;
	REACT_APP_DIRECTUS_BASE_URL: string;
};

const envConfig: Environment = {
    REACT_APP_IDENTITYSERVER_API_BASE_URL: (REACT_APP_ENV === 'prod' ? 'https://auth-api.invopayment.com/' : (REACT_APP_ENV === 'staging' ? 'https://auth-api-staging.invopayment.com/' : 	'https://auth-api-dev.invopayment.com/')),
    REACT_APP_PROFILE_BASE_URL: (REACT_APP_ENV === 'prod' ? 'https://pm-api.invopayment.com/' : (REACT_APP_ENV === 'staging' ? 		'https://pm-api-staging.invopayment.com/' : 			'https://pm-api-dev.invopayment.com/')),
    REACT_APP_ACCOUNTING_BASE_URL: (REACT_APP_ENV === 'prod' ? 'https://acc-api.invopayment.com/' : (REACT_APP_ENV === 'staging' ? 	'https://acc-api-staging.invopayment.com/' : 			'https://acc-api-dev.invopayment.com/')),
    REACT_APP_MAIN_BASE_URL: (REACT_APP_ENV === 'prod' ? 'https://invopayment.com/' : (REACT_APP_ENV === 'staging' ? 					'https://staging.invopayment.com/' : 					'https://dev.invopayment.com/')),
    REACT_APP_GRAPHQL_BASE_URL: 'https://gateway' + (REACT_APP_ENV || '') + '-api.invopayment.com/',
	REACT_APP_ASSETS_BASE_URL: (REACT_APP_ENV === 'prod' ? 'https://assets-api.invopayment.com/' : (REACT_APP_ENV === 'staging' ? 	'https://assets-api-staging.invopayment.com/' : 		'https://assets-api-dev.invopayment.com/')),
	REACT_APP_DIRECTUS_BASE_URL: (REACT_APP_ENV === 'prod' ? 'https://directus.invopayment.com/' : (REACT_APP_ENV === 'staging' ? 'https://directus.invopayment.com/' : 'https://directus.invopayment.com/')),
	CLIENT_SECRET: 'YXV0aGVudGljYXRpb25hZG1pbjp7QzBERTFFNzUtMzEzRi00NkYwLUI2QUEtMTFEQzBDNUE2NTBEfQ==',
    REACT_APP_VERSION: REACT_APP_VERSION
};

export default envConfig;
