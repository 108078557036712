import { ContentTypes } from './constants';
import ContentTypeBuilder from './ContentTypeBuilder';

export default class HttpHeaderBuilder {
  static Build(contentType: ContentTypes, authorization: string) {
    let header = {
      'Content-Type': ContentTypeBuilder.GetContetnType(contentType),
      Authorization: `${authorization}`,
    };

    return header;
  }
}
