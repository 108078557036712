import { isEmpty } from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';

import { AccountInterface } from '../Models/Account';

// import { useLazyQuery } from '@apollo/client';
import StorageHandler from '../Utils/StorageHandler';
// import { accountQuery } from '../graphQl/queries/accountQuery';

interface ContextState {
  setUserAccount: (account: AccountInterface) => void;
  userAccountDetails: AccountInterface;
  isAuthenticated: () => boolean;
}

type Props = {
  children: React.ReactNode;
};

const AppContext = createContext({} as ContextState);

const AppProvider = ({ children }: Props) => {
  //TODO: reftch from user account endpoint
  // const getInitialAccountState = () => Storage.get("userAccount") || null;
  const [userAccountDetails, setUserAccountDetails] =
    useState<AccountInterface>({});

  // const [fetchAccount, { data: accountDetail, loading, error }] = useLazyQuery(
  //   accountQuery,
  //   {
  //     fetchPolicy: 'no-cache',
  //   }
  // );
  const setUserAccount = (account: AccountInterface) => {
    setUserAccountDetails(account);
    // //TODO: Remove this
    // Storage.saveToLocalStorage("userAccount", account);
  };
  const isAuthenticated = () => {
    return !!userAccountDetails?.email;
  };

  useEffect(() => {
    // const isEmpty = Object.values(userAccountDetails).length === 0;
    const token = StorageHandler.get('token');

    if (isEmpty(userAccountDetails) && token) {
      // fetchAccount();
    }
  }, []);

  // useEffect(() => {
  //   if (accountDetail && !loading) {
  //     setUserAccount(accountDetail?.auth?.bySession);
  //   }
  // }, [accountDetail]);
  return (
    <AppContext.Provider
      value={{
        setUserAccount,
        userAccountDetails,
        isAuthenticated,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppData = (): ContextState => {
  const appData = useContext(AppContext);
  if (appData === undefined) {
    throw new Error('useAppData must be used within a AppProvider');
  }
  return appData;
};

export default AppProvider;
