import axios, {AxiosInstance} from 'axios';
import envConfig from '../Configs/env.config';

import {ROUTE_CONSTANTS} from '../Routes/RouteConstants';

import TokenHandler from '../Utils/TokenHandler';

interface RefreshTokenInterface {
    grant_type: string;
    refresh_token: string;
}

const instance: AxiosInstance = axios.create();
const POST_AUTH_ENDPOINT: string = 'connect/token';
const FIREBASE_TOKEN_ENDPOINT: string = 'api/v1/ClientFirebaseToken';
let failedRequestsQueue: Array<(error: any, token: string) => void> = [];
let authUrl = envConfig.REACT_APP_IDENTITYSERVER_API_BASE_URL + POST_AUTH_ENDPOINT;
let isRefreshing = false;

const processFailedRequestsQueue = (error: any, token: string) => {
    failedRequestsQueue.forEach((prom) => {
        prom(error, token);
    });
    failedRequestsQueue = [];
};

const refreshAccessToken = async (refreshToken: RefreshTokenInterface) => {
    let clientSecret = envConfig.CLIENT_SECRET;

    let response = await instance
        .post(authUrl, refreshToken, {
            headers: {
                Authorization: `Basic ${clientSecret}`,
                'content-type': 'application/json',
            },
        })
        .then(async (result) => {
            TokenHandler.clearAllTokens();
            TokenHandler.setToken(result.data.access_token);
            TokenHandler.setRefreshToken(result.data.refresh_token);
            isRefreshing = false;
            return result.data.access_token;
        });

    return response;
};

/**
 * Logout handler
 * @param error
 * @returns {Promise<never>}
 */
const logoutHandler = (error: any) => {
    TokenHandler.clearAllTokens();
    window.location.replace(ROUTE_CONSTANTS.HOME);

    return Promise.reject(error);
};

instance.interceptors.request.use(
    (req) => {
        return {
            ...req,
        };
    },
    (err) => {
        return Promise.reject();
    }
);

instance.interceptors.response.use(
    (res) => {
        return {...res};
    },
    async function (err) {
        const originalRequest = err.config;

        if (err.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedRequestsQueue.push((error: any, token: any) => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        resolve(axios(originalRequest));
                    });
                });
            }

            isRefreshing = true;
            const refreshToken: RefreshTokenInterface = {
                refresh_token: TokenHandler.getRefreshToken(),
                grant_type: 'refresh_token',
            };
            try {
                console.log('refreshing token')
                const token = await refreshAccessToken(refreshToken);
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                if (!!token) {
                    processFailedRequestsQueue(null, token);
                }
            } catch (error) {
                await logoutHandler(err);
            }
            return instance(originalRequest);
        } else if (err.response.status === 403 || err.response?.data?.error === 'invalid_request') {
            await logoutHandler(err);
        }

        return Promise.reject(err);
    }
);
export default instance;
