import "./BlogHeader.scss";

const BlogHeader = () => {
    return (
		<div className="blog-banner">
			<div className="d-flex align-items-center">
				<div className="back-nav">
					<a className="back-link-svg" href="https://www.inplaytip.com/">
						<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path className="path-class" d="M8 1.52344L3.03797 6.5L8 11.4766L6.48101 13L1.92713e-07 6.5L6.48101 -6.6397e-08L8 1.52344Z" fill="#464A53"></path>
						</svg>
					</a>
					<a className="back-link-text" href="javascript:window.history.back();">Back</a>
				</div>

				<div className="blog-heading">
					<h1>Blog</h1>
				</div>
						
			</div>
		</div>
)}

export default BlogHeader;