import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import CookieConsent from "react-cookie-consent";
import {useTranslation} from "react-i18next";

import Header from "../Components/Resources/Header/Header";
import Footer from "../Components/Resources/Footer/Footer";
import LoginRegisterPopup from "../Components/LoginRegisterPopup/LoginRegisterPopup";
import OtpPopup from "../Components/OtpPopup/OtpPopup";
import TermsOfService from "../Pages/Terms";

import {ReactComponent as CookieIcon} from "../Assests/Images/cookie.svg";

import AuthService from "../Services/Auth/AuthService";

import {ROUTE_CONSTANTS} from "../Routes/RouteConstants";
import {TOAST_BOTTOM_CENTER, TOAST_DURATION} from "../Constants/Values";

import Toast from "../Utils/ToastHandler";

import "./Styles.scss";

type Props = {
    isHomepage?: boolean;
    children: React.ReactNode;
    customTitle?: string;
};
const MainWrapper = ({children, isHomepage, customTitle}: Props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [phone, setPhone] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [otpMethod, setOtpMethod] = useState<string>("");
    const [isOtpPopupOpen, setIsOtpPopupOpen] = useState<boolean>(false);
    const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const [isTermsOpened, setIsTermsOpened] = useState<boolean>(false);

    const phoneVerificationHandler = (phoneNumber: string) => {
        const phonePayload = phoneNumber.replace(/\D/g, "");
        setPhone(phoneNumber);
        setLoading(true);
        // AuthService.authorizePhoneNumber(phonePayload)
        //     .then((res) => {
        //         setOtpMethod(res)
        //         setLoading(false);
        //         setIsOtpPopupOpen(true)
        //         setIsLoginPopupOpen(false);
        //         Toast.success(t("otp-sent-" + res), TOAST_DURATION, TOAST_BOTTOM_CENTER);
        //     })
        //     .catch((err) => {
        //         setLoading(false);
        //         setIsLoginPopupOpen(false);

        //         const error = err?.response?.data?.Error?.Message || "";

        //         if (error && error === "PartialRegistrationError") {
        //             navigate(ROUTE_CONSTANTS.TELEGRAM, {state: {phone: phoneNumber}});

        //         } else if (error && error === "RegistrationError") {
        //             Toast.error(
        //                 t("Registration-Error-Login"),
        //                 TOAST_DURATION,
        //                 TOAST_BOTTOM_CENTER
        //             );
        //         } else {
        //             Toast.error(
        //                 error ? t(error) : t("generic-error"),
        //                 TOAST_DURATION,
        //                 TOAST_BOTTOM_CENTER
        //             );
        //         }
        //     });
    };

    const registerPhoneHandler = (phoneNumber: string) => {
        const phonePayload = phoneNumber.replace(/\D/g, "");
        setPhone(phoneNumber);
        setLoading(true);
        AuthService.registerPhoneNumber(phonePayload)
            .then((res) => {
                setOtpMethod(res)
                setLoading(false);
                setIsOtpPopupOpen(true)
                setIsLoginPopupOpen(false);
                Toast.success(t("otp-sent-" + res), TOAST_DURATION, TOAST_BOTTOM_CENTER);
            })
            .catch((err) => {
                const error = err?.response?.data?.Error?.Message || "";

                setLoading(false);
                setIsLoginPopupOpen(false);
                if (error && error === "PartialRegistrationError") {
                    navigate(ROUTE_CONSTANTS.TELEGRAM, {state: {phone: phoneNumber}});
                } else if (error && error === "RegistrationError") {
                    Toast.error(
                        t("Registration-Error-Register"),
                        TOAST_DURATION,
                        TOAST_BOTTOM_CENTER
                    );
                } else {
                    Toast.error(
                        error ? t(error) : t("generic-error"),
                        TOAST_DURATION,
                        TOAST_BOTTOM_CENTER
                    );
                }
            });
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const action = urlParams.get("action");

        if (action === "register") {
            setIsRegistered(true);
            setIsLoginPopupOpen(true);
        } else if (action === "login") {
            setIsRegistered(false);
            setIsLoginPopupOpen(true);
        } else if (action === "terms-of-use") {
            setIsTermsOpened(true);
        }

    }, []);

    return (
        <div>
            <Header isHomepage={isHomepage} customTitle={customTitle} setLoginPopupOpen={setIsLoginPopupOpen}/>

           
            {children}
            <Footer/>

        </div>
    );
};

export default MainWrapper;
