import toast, { Renderable, Toast, ValueOrFunction } from 'react-hot-toast';

type ToastPosition =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right';

class ToastHandler {
  private TOAST_DURATION: number = 2000;
  private TOAST_POSITION = 'bottom-left' as ToastPosition;

  error(message: string, duration?: number | null, position?: ToastPosition) {
    toast.error(message, {
      position: position ? position : this.TOAST_POSITION,
      duration: duration ? duration : this.TOAST_DURATION,
	  style: { backgroundColor: "#25C16F"}
    });
  }

  success(
    message: string | ValueOrFunction<Renderable, Toast>,
    duration?: number | null,
    position?: ToastPosition
  ) {
    toast.success(message, {
      position: position ? position : this.TOAST_POSITION,
      duration: duration ? duration : this.TOAST_DURATION,
    });
  }
}

export default new ToastHandler();
