import MainWrapper from "../../Layouts/MainWrapper";

import "./Styles.scss";

const PrivacyPolicy = () => {

    return (
        <MainWrapper isHomepage={false} customTitle="Privacy Policy">
            <article className="mb-4 privacy-container">
                <div>
                    <h3>Privacy Policy</h3>
					<p>
						<b>Version 1.0 (15/12/2022)</b>
					</p>
                    <p>
						<b>Ymdm Ltd</b>, a company incorporated under the Laws of Malta, bearing the company 
						registration number C 103838 and having its registered address at FORTH BUSINESS 
						CENTRE, LEVEL 02, SUITE 9, TESTAFERRATA STREET, TA' XBIEX, Malta is the data controller of the
						website (inplaytips.com), its <b>Inplaytip</b>(the <b>"Portal"</b>) and services pursuant thereto the <b>"Services"</b>).
						<br/><br/>
						The terms “we”, “us” and “our” shall be construed accordingly.
						<br/><br/>
						We recognize our obligations as data controllers and are committed to complying with applicable data protection law. We respect your privacy and are committed to ensuring that your personal data is protected.

					</p>

					<p>
						<b>1. What is personal data	?</b>
					</p>

                    <p>
					
                    </p>

                    <p>
						"Personal data" refers to all information which can render you personally identifiable, 
						such as your name, surname and email address and includes all information and 
						documentation that can arise or be produced which can identify you personally.
                    </p>

					<p>
						<b>2. How do we collect your personal data and what personal data do we process?</b>
					</p>

                    <p>
					
                    </p>

                    <p>
						We collect personal data about you in the following ways:
                    </p>
                    <p>
                        <ul>
							<li>When you choose to contact us via the Website or through our details 
								accessible here, we may process any personal data you include within your 
								correspondence.
							|</li>
							<li>
								When you access the Website, please note that the Website uses cookies. 
								Kindy refer to the Cookie Policy for further information on our cookies.
							</li>
							<li>
								When you choose to register an account on our Website to view the 
								functionality of the Portal as your company may be interested in subscribing to 
								the Portal and related Services (the "Viewing Account"), we will process the 
								personal data that you provide to us as set out in this registration page.
							</li>
							<li>
								When you hold a functional account to access the Portal and be able to 
								request Services (the "Functional Account"), please note that you hold such 
								Functional Account pursuant to an agreement reached between us and your 
								company. The personal data collected by us will be such personal data as 
								may be required to be provided to us in terms of the agreement reached with 
								your company.
							</li>
						</ul>
                    </p>
					<p>
						<b>3. Purposes and Legal Basis for Processing</b>
					</p>

                    <p>
					
                    </p>

					<p>
						We process your personal data for the following purposes:
                    </p>
                    <p>
                        <ul>
							<li>	
								When you choose to contact us, we will process any personal data provided to 
								us in order to act upon your correspondence and/or to respond to your correspondence. 
								We process such personal data on the basis of your consent provided to us by the act 
								of contacting us with such correspondence.
							|</li>
							<li>
								When you choose to register a Viewing Account, we will process the personal 
								data as on the basis of and as set out in the Terms and Conditions.
							</li>
							<li>
								When you hold a Functional Account, we will process the personal data to perform 
								the Services agreed, enable Portal access, and as otherwise required for the 
								purposes and on the basis of the agreement reached with your company.
							</li>
						</ul>
                    </p>
					<p>
						We will also process any personal data that may be provided to us for any purposes required 
						to comply with applicable laws as well as any purposes which may be inherently in our legitimate 
						interests, including compliance checks and KYC screening, credit reference checks, as well as for 
						defending or instituting legal actions or proceedings.
					</p>
					<p>
					
                    </p>

					<p>
						<b>4. Information about other people</b>
                    </p>
                    <p>
						If you provide information to us about any person other than yourself, you must ensure that they 
						understand how their information will be used, and that they have given their permission for you 
						to disclose it to us and for you to allow us, and any of our outsourced service providers, to use it.
					</p>
					<p>
					
                    </p>

					<p>
						<b>5. Marketing</b>
                    </p>
                    <p>
						It shall be in our legitimate interest to process the email address of the holder of a Viewing Account 
						and/or a Functional Account provided to us while registering for the respective accounts, for the purposes 
						of us sending emails to such email address which emails promote our own products and services.
					</p>
					<p>
						Should you wish to no longer receive such promotional emails, you may do so at any time by emailing 
						support@inplaytip.com or click the “Unsubscribe” link at the footer of any such promotional email 
						correspondence we may choose to send to you.
					</p>
					<p>
					
                    </p>

					<p>
						<b>6. Data Retention</b>
                    </p>
                    <p>
						Your personal data shall be retained exclusively for the period in which it is necessary to fulfil the 
						purpose of collection.
					</p>
					<p>
						If you hold any account with us, it is generally envisaged that the personal data will be mainly held 
						for up to 5 years from the date of closure of the respective account.
					</p>
					<p>
						Where we process your personal data on the basis of your consent, we will process it only till necessary 
						to fulfil the purposes consented or till you withdraw your consent, whichever is earlier.
					</p>
					<p>
						If you want to learn more about any specific matter regarding our data retention or
						retention periods established in our Data Retention Policy, ou may contact us at support@inplaytip.com.
					</p>
					<p>
						Upon expiry of the applicable retention period we will securely destroy your personal 
						data in accordance with applicable laws and regulations.
					</p>
					<p>
					
                    </p>

					<p>
						<b>7. Data Security</b>
                    </p>
                    <p>
						We take the protection of your personal data very seriously and are committed to take the appropriate technical 
						and organisation measures to protect your data against unauthorised or unlawful processing.
					</p>
					<p>
					
                    </p>

					<p>
						<b>8. Your rights</b>
                    </p>
                    <p>
						You enjoy various rights in relation to your personal data:
						<ul>
							<li>
								<b>Right to Withdraw Consent </b>– you have the right to withdraw your consent at any time where the processing 
								was based on your consent. The withdrawal of your consent will have no affect on the lawfulness of processing
								based on consent before its withdrawal. Consent may be withdrawn by contacting support@inplaytip.com.
							</li>
							<li>
								<b>Right of Access</b> – the right to ascertain whether we hold personal data about you and to receive a 
								copy of such personal data.
							</li>
							<li>
								<b>Right to Complain </b>– if you have any complaints regarding our processing of your personal data 
								note that you may contact us at the contact details provided below. You also have a right to lodge a 
								complaint with the Information and Data Protection Commissioner in Malta. The Information and Data 
								Protection Commissioner may be contacted at idpc.gov.mt.
							</li>
							<li>
								<b>Right to Erasure</b> – you have a right to request that we delete your personal data in certain 
								circumstances.
							</li>
							<li>
								<b>Right to Object </b>– you have a right to object to our processing of your personal data and 
								request that we stop such processing where we rely on our or a third party’s legitimate interest/s.
							</li>
							<li>
								<b>Right to Data Portability</b> – you have a right to request that we port certain personal data 
								in a structured, commonly used and machine-readable format. If technically feasible, you may also 
								request that such data is transmitted by us directly to a third-party controller of your choice.
							</li>
							<li>
								<b>Right to Rectification</b> – you have the right to update, correct and rectify any inaccurate 
								personal data we process about you.
							</li>
							<li>
								<b>Right to Restriction</b> – you have a right to request that we restrict your personal data in certain circumstances.
							</li>
						</ul>
					</p>
					<p>
					
                    </p>

					<p>
						<b>9. Recipients of your personal data</b>
                    </p>
                    <p>
						We do not sell your personal data to third parties. Only our employees, contractors, suppliers, advisors and representatives who 
						require access to such personal data on a strict need to know basis will have access to your personal data.
					</p>
					<p>
					
                    </p>

					<p>
						<b>10. Automated Decision Making and Profiling</b>
                    </p>
                    <p>
						Your personal data will not be processed for the purposes of any decisions taken solely on the basis of profiling 
						methods or algorithmic/automated decision-making without human intervention.
					</p>
					<p>
					
                    </p>

					<p>
						<b>11. Contact Details</b>
                    </p>
                    <p>
						If you have any queries regarding our personal data processing practices, do not hesitate to contact us on 
						support@inplaytip.com. 
					</p>
					<p>
					
                    </p>

					<p>
						<b>12. Updates</b>
                    </p>
                    <p>
						We may update and change this Privacy Notice at any time by posting the newer version on the Website. 
						If any change made affects your consent, we will not proceed with such processing before we collect 
						your consent again following notification of the respective updates / changes.
						<br/><br/>
						If you hold any account with us, we will also inform you that the Privacy Notice has changed by 
						emailing you at the respective email address supplied to us when registering the account.
					</p>
                </div>
            </article>
        </MainWrapper>
    );
};

export default PrivacyPolicy;
