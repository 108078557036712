import {useContext, useEffect, useState} from "react";
import useMatchMedia from "../../../Hooks/useMatchMedia";
import {MOBILE_MAX_WIDTH_QUERY} from "../../../Constants/Values";
import {ReactComponent as BackCircleIcon} from "../../../Assests/Icons/backCircle.svg";
import { useNavigate } from "react-router";



import "./Header.scss";
import { ROUTE_CONSTANTS } from "../../../Routes/RouteConstants";

type HeaderProps = {
    isHomepage: boolean
    customTitle?: string
    setLoginPopupOpen: React.Dispatch<React.SetStateAction<boolean>>
};

const Header = ({isHomepage, customTitle, setLoginPopupOpen}: HeaderProps) => {
    const {match: isMobileDevice} = useMatchMedia(MOBILE_MAX_WIDTH_QUERY);
	const isLoginPage = window.location.pathname === '/login';


	const navigate = useNavigate();

    useEffect(() => {
        let prevScrollpos = window.pageYOffset;
        const headerElement = document.getElementById("mainNav");

        const scrollHandler = () => {
            const currentScrollPos = window.pageYOffset;

            if (currentScrollPos > 300) {
                headerElement?.classList.add("is-fixed");
                headerElement?.classList.remove("is-visible");
            }

            if (prevScrollpos > currentScrollPos && currentScrollPos > 300) {
                headerElement?.classList.add("is-visible");
            }

            if (prevScrollpos > currentScrollPos && currentScrollPos === 0) {
                headerElement?.classList.remove("is-fixed");
                headerElement?.classList.remove("is-visible");
            }

            prevScrollpos = currentScrollPos;
        };

        if (isMobileDevice) {
            headerElement?.classList.add("is-fixed");
            headerElement?.classList.add("is-visible");
        } else {
            window.addEventListener("scroll", scrollHandler);
        }

    }, [isMobileDevice]);


    const registrationToggleHandler = () => {

        setLoginPopupOpen(true);

        // setIsRegistered((prevState) => !prevState);
        //
        // if (window.location.pathname === "/home" || window.location.pathname === "/") return;
        //
        // window.location.href = "/home";
    }

    return (
		<header className={'u-clearfix u-header u-header'} id={'sec-a184'}>
			<a href="/" className="u-image u-logo u-image-1" data-image-width="469" data-image-height="108">
        		<img src="/Images/logo2.png" className="u-logo-image u-logo-image-1" />
      		</a>
			
			<nav className="u-menu u-menu-hamburger u-offcanvas u-menu-1" data-responsive-from="XL">
        		<div className="menu-collapse" >
          			<a className="u-button-style u-custom-active-color u-custom-border u-custom-border-color u-custom-hover-color u-custom-left-right-menu-spacing u-custom-padding-bottom u-custom-text-active-color u-custom-text-color u-custom-text-hover-color u-custom-top-bottom-menu-spacing u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="#">
						<svg className="u-svg-link" viewBox="0 0 24 24"><use href="#menu-hamburger"></use></svg>
            			<svg className="u-svg-content" version="1.1" id="menu-hamburger" viewBox="0 0 16 16" x="0px" y="0px"  xmlns="http://www.w3.org/2000/svg">
							<g>
								<rect y="1" width="16" height="2"></rect>
								<rect y="7" width="16" height="2"></rect>
								<rect y="13" width="16" height="2"></rect>
							</g>
						</svg>
          			</a>
        		</div>
        		<div className="u-custom-menu u-nav-container">
          			<ul className="u-nav u-spacing-2 u-unstyled u-nav-1">
						<li className="u-nav-item">
							<a className="u-active-grey-5 u-button-style u-hover-grey-10 u-nav-link u-text-active-grey-90 u-text-grey-90 u-text-hover-grey-90" href="#" style={{padding:"10px 20px"}}>Home</a>
						</li>
					</ul>
        		</div>
        		<div className="u-custom-menu u-nav-container-collapse">
          			<div className="u-black u-container-style u-inner-container-layout u-opacity u-opacity-95 u-sidenav">
            			<div className="u-inner-container-layout u-sidenav-overflow">
              				<div className="u-menu-close"></div>
              				<ul className="u-align-center u-nav u-popupmenu-items u-unstyled u-nav-2">
								<li className="u-nav-item">
									<a className="u-button-style u-nav-link" href="#" style={{padding:"10px 20px"}}>Home</a>
								</li>
							</ul>
            			</div>
          			</div>
          			<div className="u-black u-menu-overlay u-opacity u-opacity-70"></div>
        		</div>
      		</nav>
        	<div className="container ps-1 inplay-container btncon">

				{isLoginPage && (
                    <button
                        id="login-nav-button"
                        type="button" onClick={() => navigate(ROUTE_CONSTANTS.REGISTER)}>
                        Register
                    </button> 
				)}
                {!isLoginPage && (
                    <button
                        id="login-nav-button"
                        type="button" onClick={() => navigate(ROUTE_CONSTANTS.LOGIN)}>
                        Login
                    </button> 
				)}
            </div>
		</header>
    );
};

Header.defaultProps = {
    isHomepage: true,
};

export default Header;
