import { FormEvent, useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';

import TermsOfService from '../Terms';

import AssetsService from '../../Services/Assets/AssetsService';
import AuthService from '../../Services/Auth/AuthService';

import { TOAST_BOTTOM_CENTER, TOAST_DURATION } from '../../Constants/Values';
import Toast from '../../Utils/ToastHandler';

import './Styles.scss';
import MainWrapper from '../../Layouts/MainWrapper';

type FormData = {
  phone: string;
};

const Register = () => {
	const [name, setName] = useState('');
	const [surname, setSurname] = useState('');
	const [email, setEmail] = useState('');
  
	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
	  e.preventDefault();
	  // Implement your registration logic here
	  console.log('Name:', name);
	  console.log('Surname:', surname);
	  console.log('Email:', email);
	};

  return (
	<MainWrapper>
 <section className="u-align-center u-clearfix u-image u-shading u-section-1" id="carousel_5ad0"  data-image-width="1782" data-image-height="1080">
		<div className="u-clearfix u-sheet u-valign-middle-xs u-sheet-1">
		  <h1 className="u-custom-font u-font-montserrat u-text u-text-body-alt-color u-title u-text-1">Register</h1>
		  <div className="container1">
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="surname">Surname</label>
          <input
            type="text"
            id="surname"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
		<a type="submit" className="u-active-white u-border-2 u-border-white u-btn u-button-style u-hover-white u-none u-text-active-black u-text-body-alt-color u-text-hover-black u-btn-1">Register</a>
      </form>
    </div>
	</div>
	</section>
	</MainWrapper>
  );
};

export default Register;
