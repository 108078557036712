// import { ApolloProvider } from "@apollo/client";
import {Suspense, useEffect} from "react";
import {Toaster} from "react-hot-toast";
import {BrowserRouter} from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import "./App.scss";
import AppProvider from "./Context/AppContext";
import Router from "./Routes/Routes";
// import { client } from "./Services/apollo-client";
import "./i18n/i18n";
import {ConfigProvider} from "antd";

function App() {
    return (
        <BrowserRouter>
                <AppProvider>
                    {/* <ErrorHandler>
			</ErrorHandler> */}
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: '#0B2911',
                                colorBgContainer: '#f8f9fa',
                            },
                        }}
                    >
                        <Suspense fallback={"..."}>
                            <Toaster
                                toastOptions={{
                                    style: {
                                        fontFamily: "Arial, sans-serif",
                                        fontSize: "16px",
                                    },
                                }}
                            />
                            <Router/>
                        </Suspense>
                    </ConfigProvider>
                </AppProvider>
        </BrowserRouter>
    );
}

export default App;
