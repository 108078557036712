import FactoryService from './FactoryService';

import DirectUsBaseBaseService from "./DirectUsBaseBaseService";
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
const factoryService = new FactoryService();

class ArticlesService {
    private HISTORY_ENDPOINT = 'items/articles';

	
    async getArticles(searchTerm: string | null, pageNumber: number = 1, pageSize: number = 10) {
        let queryParam = '?';
        if (searchTerm !== null && searchTerm !== '') {
            queryParam += `Search=${searchTerm}&`;
        }

		const client = new ApolloClient({
			uri: "https://directus.invopayment.com/graphql"	,
			cache: new InMemoryCache(),
		  });

		  const { loading, error, data } = await client.query({
			query: gql`
			  query {
				articles(limit: ${pageSize}, offset: ${(pageNumber - 1) * pageSize}) {
					slug,
					author,
					published_date,
					image {
						id
					  },
					translations(filter: { languages_id: { _eq: "en-US" }}) {
						languages_id
						content
						title
					  }		
				}

				slug_count: articles_aggregated {
					count {
						slug
					}
				},
			  }
			`,
		  });
	
		  return data;
    }

	async getArticle(slug: string) {
       

		const client = new ApolloClient({
			uri: "https://directus.invopayment.com/graphql"	,
			cache: new InMemoryCache(),
		  });

		  const { loading, error, data } = await client.query({
			query: gql`
			  query {
				articles_by_id(id: "${slug}") {
					slug,
					author,
					published_date,
					image {
						id
					  },
					translations(filter: { languages_id: { _eq: "en-US" }}) {
						languages_id
						content
						title
					  }		
				}
			  }
			`,
		  });

		  return data?.articles_by_id;
    }

}

export default new ArticlesService();